import React from 'react'
import styled from 'styled-components'
import { FaYoutube, FaSpotify, FaSoundcloud, FaApple } from 'react-icons/fa'

const Header = () => {
  return (
    <HeaderNav>
      <Menu>
        <ItemMenu>
          <Icon>
            <a
              target='__blank'
              href='https://open.spotify.com/artist/6oGTl5yFX9qiHeQG6gmCPu?si=hZ0EBDWHQw6VyYVjRhQ7Qw'
            >
              <FaSpotify />
            </a>
          </Icon>
        </ItemMenu>
        <ItemMenu>
          <Icon>
            <a target='__blank' href='https://soundcloud.com/b-mei'>
              <FaSoundcloud />
            </a>
          </Icon>
        </ItemMenu>
        <ItemMenu>
          <Icon>
            <a
              target='__blank'
              href='https://www.youtube.com/channel/UC9YWRWBH2rhV6AuEhp7OMrQ'
            >
              <FaYoutube />
            </a>
          </Icon>
        </ItemMenu>
        <ItemMenu>
          <Icon>
            <a
              target='__blank'
              href='https://music.apple.com/fr/artist/mei-b/1488783312'
            >
              <FaApple />
            </a>
          </Icon>
        </ItemMenu>
      </Menu>
    </HeaderNav>
  )
}

const HeaderNav = styled.header`
  width: 100%;
  max-height: 60px;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
`
const Menu = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  color: #fff;
`
const ItemMenu = styled.div``
const Icon = styled.div`
  font-size: 30px;
  padding-left: 30px;
  opacity: 0.7;
  transition: All 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }
`

export default Header
