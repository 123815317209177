import React, { Fragment, useState } from 'react'
import tracks from '../tracklist'
import styled from 'styled-components'
import SelectedTrack from './SelectedTrack'

const TrackSection = () => {
  const [trackSelected, setTrackSelected] = useState(tracks[1].tracks[0])

  const selectTrack = (yearToFind, index) => {
    const yearSelected = tracks.find(data => data.year === yearToFind)
    setTrackSelected(yearSelected.tracks[index])
  }
  return (
    <TrackSectionContainer id='trackSection'>
      <BGEffectRight />
      <TracksBlock>
        {tracks &&
          tracks.map(data => (
            <BlockYears key={data.year}>
              <Year>{data.year}</Year>
              <ListTracks>
                {data.tracks.map((track, index) => (
                  <Fragment key={track.nom}>
                    <Tracks>
                      <span onClick={() => selectTrack(data.year, index)}>
                        {track.nom}
                      </span>
                    </Tracks>
                    {index + 1 !== data.tracks.length ? (
                      <Separarator>-</Separarator>
                    ) : null}
                  </Fragment>
                ))}
              </ListTracks>
            </BlockYears>
          ))}
      </TracksBlock>
      <ResultBlock>
        <SelectedTrack track={trackSelected} />
      </ResultBlock>
    </TrackSectionContainer>
  )
}

const TrackSectionContainer = styled.section`
  display: flex;
  justify-content: flex-start;
  background-color: #0c0c0c;
  height: 100%;
  position: relative;
  flex-direction: column;
`
const BGEffectRight = styled.div`
  position: absolute;
  height: 100%;
  width: 50%;
  top: 0;
  left: 50%;
  background-color: #1c1c1c;
  opacity: 0.2;
  z-index: 1;
`

const TracksBlock = styled.div`
  height: auto;
  z-index: 10;

  width: 100%;
`
const BlockYears = styled.div`
  margin: 40px auto;
  width: 45%;
  display: flex;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.normal};
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-size: 28px;
  color: #c4b4b4;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 2px;
  cursor: pointer;
  transition: All 0.5s ease-out;
  opacity: 0.5;
  height: 100px;

  @media screen and (max-width: 1024px) {
    height: auto;
    justify-content: start;
    font-size: 26px;
    opacity: 0.7;
    width: 55%;
    margin: 20px auto;
  }

  &:hover {
    font-size: 36px;
    opacity: 1;
    width: 55%;

    @media screen and (max-width: 1024px) {
      font-size: 26px;
    }
  }
`
const Year = styled.div`
  font-weight: bolder;

  @media screen and (max-width: 1024px) {
    font-size: 30px;
  }
`
const ListTracks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`
const Tracks = styled.div`
  & span:hover {
    text-decoration: underline;
  }
`
const Separarator = styled.div`
  padding: 0 5px;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`
const ResultBlock = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;
`

export default TrackSection
