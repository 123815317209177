import Header from './Components/Header'
import HeroSection from './Components/HeroSection'
import Contact from './Components/Contact'
import TrackSection from './Components/TrackSection'
import Footer from './Components/Footer'

function App () {
  return (
    <div className='App'>
      <Header />
      <HeroSection />
      <TrackSection />
      <Contact />
      <Footer />
    </div>
  )
}

export default App
