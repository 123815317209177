import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Icon } from '@iconify/react'
import nextTrackButton from '@iconify/icons-openmoji/next-track-button'
import { scroller } from 'react-scroll'

const HeroSection = () => {
  const [showSubTitle, setShowSubTitle] = useState(false)
  const [showArrow, setShowArrow] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(function () {
      setShowSubTitle(true)
    }, 1000)

    const timeoutArrow = setTimeout(function () {
      setShowArrow(true)
    }, 2000)

    return () => {
      clearTimeout(timeout)
      clearTimeout(timeoutArrow)
    }
  }, [])

  const scrollTo = () => {
    scroller.scrollTo('trackSection', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }
  return (
    <HeroContainer>
      <BGEffectRight />

      <TextBlock show={showSubTitle}>
        <img src='/img/meib.png' alt='Mei-b' />
        <h1>Mei.b</h1>
        <h2>Soundlab</h2>
      </TextBlock>
      <GoNext onClick={scrollTo} show={showArrow}>
        <Icon className='icon-next' icon={nextTrackButton} />
      </GoNext>
      <BGEffectBottom />
    </HeroContainer>
  )
}

const HeroContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0c0c0c;
  height: 100vh;
`

const BGEffectRight = styled.div`
  position: absolute;
  height: 100vh;
  width: 50%;
  top: 0;
  left: 50%;
  background-color: #101010;
`
const BGEffectBottom = styled.div`
  position: absolute;
  height: 50vh;
  width: 100vw;
  bottom: 0;
  left: 0;
  background-color: #c4c4c4;
  opacity: 0.02;
  z-index: 1;
`

const TextBlock = styled.div`
  color: #fff;
  text-align: center;
  z-index: 10;

  background-color: transparent;

  & img {
    width: 130px;
    height: 130px;
  }
  & h1 {
    font-family: ${({ theme }) => theme.fonts.big};
    font-size: 50px;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 3px;
    margin: 0;
  }

  & h2 {
    font-family: ${({ theme }) => theme.fonts.normal};
    text-transform: uppercase;
    font-size: 30px;
    font-weight: normal;
    letter-spacing: 5px;
    margin-top: -7px;
    opacity: ${({ show }) => (show ? 0.7 : 0)};
    transform: ${({ show }) => (show ? 'translateY(0)' : 'translateY(-10px)')};
    transition: all 0.5s ease-in-out;
  }
`

const GoNext = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${({ show }) =>
    show ? 'translateY(0)' : 'translateY(20px)'} !important;
  transition: all 0.5s ease-in-out;
  z-index: 20;

  & .icon-next {
    z-index: 20;
    font-size: 35px;
    opacity: ${({ show }) => (show ? 1 : 0)};

    transform: rotate(90deg) !important;
    transform-origin: 7px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;

    & g {
      stroke: #fff !important;
    }

    @media screen and (max-width: 1024px) {
      transform-origin: 17px;
    }
  }
`

export default HeroSection
