import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FaSpotify, FaSoundcloud, FaApple } from 'react-icons/fa'
import { SiDeezer } from 'react-icons/si'

const SelectedTrack = ({ track }) => {
  const [show, setShow] = useState(false)
  const [trackSelected, setTrackSelected] = useState(track)

  useEffect(() => {
    const animateCover = setTimeout(() => {
      setShow(true)
      setTrackSelected(track)
    }, 500)

    return () => {
      clearTimeout(animateCover)
      setShow(false)
    }
  }, [track])

  if (trackSelected === null) {
    return null
  }

  return (
    <SelectTrackContainter>
      <Cover show={show}>
        <img alt='cover' src={`/cover-tracks/${trackSelected.cover}`} />
      </Cover>
      <InfosDiffuser>
        {trackSelected.sites &&
          Object.keys(trackSelected.sites).map(
            (site, index) =>
              trackSelected.sites[site] !== null && (
                <Diffuser
                  key={site}
                  site_name={site}
                  url={trackSelected.sites[site]}
                />
              )
          )}
      </InfosDiffuser>
    </SelectTrackContainter>
  )
}

const Diffuser = ({ site_name, url }) => {
  const sites = site_name.split('_')
  const site = sites[0]

  const geticonBySite = site => {
    switch (site) {
      case 'spotify':
        return <FaSpotify />
      case 'soundcloud':
        return <FaSoundcloud />
      case 'apple':
        return <FaApple />
      case 'deezer':
        return <SiDeezer />
      default:
        return null
    }
  }

  // Icon en fonction du site
  const icon = geticonBySite(site)

  return (
    <DiffuserContainer>
      <a target='__blank' href={url}>
        {icon}
      </a>
    </DiffuserContainer>
  )
}

const SelectTrackContainter = styled.div``
const Cover = styled.div`
  width: 350px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 420px) {
    width: 300px;
    height: 200px;
  }

  & img {
    width: ${({ show }) => (show ? '350px' : '0px')};
    height: ${({ show }) => (show ? '350px' : '0px')};
    border-radius: 100%;
    transition: All 0.3s ease-in-out;

    @media screen and (max-width: 420px) {
      width: ${({ show }) => (show ? '200px' : '0px')};
      height: ${({ show }) => (show ? '200px' : '0px')};
    }
  }
`
const InfosDiffuser = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
`

const DiffuserContainer = styled.div`
  font-size: 30px;
  padding: 0 15px;
`

export default SelectedTrack
