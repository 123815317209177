import { createGlobalStyle } from 'styled-components'

export const Theme = {
  colors: {
    primary: '#f2431f',
    secondary: '#202034'
  },
  fonts: {
    big: 'Anton, sans-serif',
    normal: 'Chathura, sans-serif'
  }
}

export const GlobalStyle = createGlobalStyle`



  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
  }

  a{
    text-decoration:none;
    color:#ffff;

  }

`
