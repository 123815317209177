const tracks = [
  {
    year: 2019,
    tracks: [
      {
        nom: 'Reset Memory',
        cover: 'reset_memory.jpg',
        sites: {
          spotify_url:
            'https://open.spotify.com/track/50u66E1U3N4mZVexRezcDp?si=pEga_i-zQDuJfc7MMW-zHQ',
          soundcloud_url: 'https://soundcloud.com/b-mei/reset-memory',
          apple_url: null,
          deezer_url: null
        }
      },
      {
        nom: 'Carpe Diem',
        cover: 'carpe_diem.png',
        sites: {
          spotify_url:
            'https://open.spotify.com/track/40cNJYsv8RCnvJtXH9vIs8?si=32U-fJ0NSgSM5BEBZu9Gpg',
          soundcloud_url: 'https://soundcloud.com/b-mei/carpe-diem',
          apple_url: null,
          deezer_url: null
        }
      },
      {
        nom: 'Puppet Time',
        cover: 'puppet_time.png',
        sites: {
          spotify_url:
            'https://open.spotify.com/track/3xzJZb9fDAB7Cf7wJffYHU?si=BhQLuN4jTnWzy1AYKDzA9Q',
          soundcloud_url: 'https://soundcloud.com/b-mei/puppet-time',
          apple_url: null,
          deezer_url: 'https://deezer.page.link/J5GzofCwW2jHGf856'
        }
      },
      {
        nom: "We're gonna come all the way",
        cover: 'we_re_gonna_come_all_the_way.jpg',
        sites: {
          spotify_url:
            'https://open.spotify.com/track/5V8ouj0gVWMxlK55NIOmtW?si=JSVU0gPcT3iM28IXWHKDRA',
          soundcloud_url:
            'https://soundcloud.com/b-mei/were-gonna-come-all-the-way',
          apple_url: null,
          deezer_url: 'https://deezer.page.link/G5GSwL7rDg93UXVDA'
        }
      },
      {
        nom: 'All you need is friends',
        cover: 'all_you_need_is_friends.png',
        sites: {
          spotify_url:
            'https://open.spotify.com/track/4CTZSNvzGdIteKeHSeONbl?si=v-eUZBjeRE6VASLdFFw_gg',
          soundcloud_url:
            'https://soundcloud.com/b-mei/all-you-need-is-friends',
          apple_url:
            'https://music.apple.com/fr/album/all-you-need-is-friends-single/1489064225',
          deezer_url: 'https://deezer.page.link/rF8AkY2brYZuvEvK9'
        }
      }
    ]
  },
  {
    year: 2020,
    tracks: [
      {
        nom: 'Where are you',
        cover: 'where_are_you.jpg',
        sites: {
          spotify_url:
            'https://open.spotify.com/track/6qa42pQddx6oM4GDPwI4L9?si=kXJqYmaLTpOSjpR5286sVQ',
          soundcloud_url: 'https://soundcloud.com/b-mei/where-are-you',
          apple_url:
            'https://music.apple.com/fr/album/where-are-you-single/1528091694',
          deezer_url: 'https://deezer.page.link/zEyHRJYYCmQTUcdCA'
        }
      },
      {
        nom: 'Tell me',
        cover: 'tellme.jpg',
        sites: {
          spotify_url:
            'https://open.spotify.com/track/1sMQWELptJIIokUkEgaYhw?si=6ZgCBJWnSc-lA1Wj7lgIFw',
          soundcloud_url: 'https://soundcloud.com/b-mei/tell-me',
          apple_url:
            'https://music.apple.com/fr/album/tell-me-single/1533768593',
          deezer_url: null
        }
      }
    ]
  }
]

export default tracks
