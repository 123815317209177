import React from 'react'
import styled from 'styled-components'

const Footer = () => {
  return (
    <FooterContainer>
      <BGEffectRight />
      <span>Site créé par Jean-Maxime Bréham</span>
    </FooterContainer>
  )
}

const FooterContainer = styled.div`
  background-color: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #ffff;
  padding-bottom: 10px;

  position: relative;
  height: 100%;

  & span {
    opacity: 0.7;
  }
`

const BGEffectRight = styled.div`
  position: absolute;
  height: 100%;
  width: 50%;
  top: 0;
  left: 50%;
  background-color: #1c1c1c;
  opacity: 0.2;
  z-index: 1;
`

export default Footer
