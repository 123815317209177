import React from 'react'
import ReactDOM from 'react-dom'
import { Normalize } from 'styled-normalize'

import { ThemeProvider } from 'styled-components'
import { GlobalStyle, Theme } from './styles/GlobalStyle'

import App from './App'
import reportWebVitals from './reportWebVitals'
import './styles/index.css'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <Normalize />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
