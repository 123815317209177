import React from 'react'
import styled from 'styled-components'

const Contact = () => {
  return (
    <Container>
      <BGEffectRight />
      <Title>Contact</Title>
      <Email>bmei.sound@gmail.com</Email>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #0c0c0c;
  position: relative;
  color: #ffff;
`

const BGEffectRight = styled.div`
  position: absolute;
  height: 100%;
  width: 50%;
  top: 0;
  left: 50%;
  background-color: #1c1c1c;
  opacity: 0.2;
  z-index: 1;
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.normal};
  text-transform: uppercase;
  font-size: 40px;
  margin-top: 200px;
  letter-spacing: 2px;
  opacity: 0.7;

  @media screen and (max-width: 420px) {
    margin-top: 100px;
  }
`
const Email = styled.div`
  font-family: ${({ theme }) => theme.fonts.big};
  font-size: 50px;
  margin: 100px;

  @media screen and (max-width: 420px) {
    font-size: 30px;
    margin: 60px;
  }
`

export default Contact
